import { colors, type Skin } from '@orus.eu/pharaoh'
import { fontweight } from '@orus.eu/pharaoh/src/foundation/font-weight-tokens'
import { PoweredByOrus } from '../powered-by-orus'
import avatarUrl from './avatar.svg'
import HorizontalLogoSvg from './logo'
import quoteHelpBannerImageUrl from './quote-help-banner-image.png'

import { css } from '@emotion/react'
import codecProUrl from './fonts/codec-pro-extra-bold.woff2?url'

const codecProFontFamily = 'CodecPro-Extrabold, sans-serif'

const notoSansUrl = 'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap'
const notoSansFontFamily = 'Noto Sans, sans-serif'

export const jumpSkin: Skin = {
  avatarUrl,
  quoteHelpBannerImageUrl: {
    fr: quoteHelpBannerImageUrl,
    es: quoteHelpBannerImageUrl,
  },
  horizontalLogo: {
    aspectRatio: 82 / 32,
    ImageComponent: HorizontalLogoSvg,
  },
  showByOrusInHeader: 'by',
  TrustComponent: PoweredByOrus,
  forcedColors: {
    color: colors.black,
    backgroundColor: colors.sky.dark,
  },
  subscriptionCalloutColors: {
    backgroundColor: '#1F0F69',
    accentColor: '#E7E4F4',
    textColor: '#E7E4F4',
  },
  stripe: {
    fontFamily: notoSansFontFamily,
    fontFamilyUrl: notoSansUrl,
  },
  additionalStylesheets: [notoSansUrl],
  additionalStyles: css`
    @font-face {
      font-family: CodecPro-Extrabold;
      src: url('${codecProUrl}') format('woff2');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }
  `,

  cssVariables: {
    '--skin-font': notoSansFontFamily,
    '--skin-font-title': codecProFontFamily,
    '--skin-font-button': codecProFontFamily,
    '--skin-font-subtitle1': codecProFontFamily,
    '--skin-font-subtitle2': codecProFontFamily,
    '--skin-font-body1': notoSansFontFamily,
    '--skin-font-weight-title': fontweight.regular,
    '--skin-font-weight-subtitle1': fontweight.regular,
    '--skin-font-weight-subtitle2': fontweight.medium,
    '--skin-font-weight-body1': fontweight.regular,
    '--skin-font-weight-body1-medium': fontweight.medium,
    '--skin-font-weight-body1-semibold': fontweight.semibold,
    '--skin-font-weight-body2': fontweight.regular,
    '--skin-font-weight-body2-medium': fontweight.medium,
    '--skin-font-weight-body2-semibold': fontweight.semibold,
    '--skin-font-weight-caption': fontweight.regular,
    '--skin-font-weight-caption-medium': fontweight.medium,
    '--skin-font-weight-button': fontweight.regular,
    '--skin-font-weight-input': fontweight.regular,
    '--skin-blue-100': 'var(--Jump_Positive-100)',
    '--skin-blue-200': 'var(--Jump_Positive-200)',
    '--skin-blue-300': 'var(--Jump_Positive-200)',
    '--skin-blue-400': 'var(--Jump_Positive-200)',
    '--skin-blue-500': 'var(--Jump_Positive-400)',
    '--skin-blue-600': 'var(--Jump_Positive-400)',
    '--skin-blue-700': 'var(--Jump_Identity-500)',
    '--skin-blue-800': 'var(--Jump_Identity-500)',
    '--skin-blue-900': 'var(--Jump_Identity-900)',
    '--skin-gray-100': 'var(--Jump_Gray-200)',
    '--skin-gray-200': 'var(--Jump_Gray-200)',
    '--skin-gray-300': 'var(--Jump_Gray-200)',
    '--skin-gray-400': 'var(--Jump_Gray-200)',
    '--skin-gray-500': 'var(--Jump_Gray-600)',
    '--skin-gray-600': 'var(--Jump_Gray-600)',
    '--skin-gray-700': 'var(--Jump_Gray-700)',
    '--skin-gray-800': 'var(--Jump_Gray-800)',
    '--skin-gray-900': 'var(--Jump_Gray-900)',
    '--skin-green-100': 'var(--Green-100)',
    '--skin-green-200': 'var(--Green-200)',
    '--skin-green-300': 'var(--Green-300)',
    '--skin-green-400': 'var(--Green-400)',
    '--skin-green-500': 'var(--Green-500)',
    '--skin-green-600': 'var(--Green-600)',
    '--skin-green-700': 'var(--Green-700)',
    '--skin-green-800': 'var(--Green-800)',
    '--skin-green-900': 'var(--Green-900)',
    '--skin-yellow-100': 'var(--Yellow-100)',
    '--skin-yellow-200': 'var(--Yellow-200)',
    '--skin-yellow-300': 'var(--Yellow-300)',
    '--skin-yellow-400': 'var(--Yellow-400)',
    '--skin-yellow-500': 'var(--Yellow-500)',
    '--skin-yellow-600': 'var(--Yellow-600)',
    '--skin-yellow-700': 'var(--Yellow-700)',
    '--skin-yellow-800': 'var(--Yellow-800)',
    '--skin-yellow-900': 'var(--Yellow-900)',
    '--skin-orange-100': 'var(--Orange-100)',
    '--skin-orange-200': 'var(--Orange-200)',
    '--skin-orange-300': 'var(--Orange-300)',
    '--skin-orange-400': 'var(--Orange-400)',
    '--skin-orange-500': 'var(--Orange-500)',
    '--skin-orange-600': 'var(--Orange-600)',
    '--skin-orange-700': 'var(--Orange-700)',
    '--skin-orange-800': 'var(--Orange-800)',
    '--skin-orange-900': 'var(--Orange-900)',
    '--skin-red-100': 'var(--Red-100)',
    '--skin-red-200': 'var(--Red-200)',
    '--skin-red-300': 'var(--Red-300)',
    '--skin-red-400': 'var(--Red-400)',
    '--skin-red-500': 'var(--Red-500)',
    '--skin-red-600': 'var(--Red-600)',
    '--skin-red-700': 'var(--Red-700)',
    '--skin-red-800': 'var(--Red-800)',
    '--skin-red-900': 'var(--Red-900)',
    '--skin-white': 'var(--Jump_Neutral-000)',
    '--skin-black': 'var(--Black)',
    '--skin-linen': 'var(--Jump_Neutral-000)',
    '--skin-sky-dark': 'var(--Sky-Dark)',
    '--skin-sky-light': 'var(--Sky-Light)',
    '--skin-sky-gradient': 'radial-gradient(400% 100% at 0% 100%, #C9F0FC 0%, #CDEFFA 100%)',
    '--skin-periwinkle-dark': 'var(--Jump_Accent1-200)',
    '--skin-periwinkle-light': 'var(--Jump_Accent1-100)',
    '--skin-periwinkle-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Jump_Accent1-200) 0%, var(--Jump_Accent1-100) 100%)',
    '--skin-mindaro-dark': 'var(--Jump_Accent1-200)',
    '--skin-mindaro-light': 'var(--Jump_Accent1-100)',
    '--skin-mindaro-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Jump_Accent1-200) 0%, var(--Jump_Accent1-100) 100%)',
    '--skin-jasmine-dark': 'var(--Jump_Accent1-200)',
    '--skin-jasmine-light': 'var(--Jump_Accent1-100)',
    '--skin-jasmine-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Jump_Accent1-200) 0%, var(--Jump_Accent1-100) 100%)',
    '--skin-peach-dark': 'var(--Jump_Accent1-200)',
    '--skin-peach-light': 'var(--Jump_Accent1-100)',
    '--skin-peach-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--Jump_Accent1-200) 0%, var(--Jump_Accent1-100) 100%)',
  },
}
